.header_box {
  height: 96px;
  background-size: cover;
  background-image: url(../../assets/9.png);
}

.logo_container {
  height: 104px;
  background-repeat: no-repeat;
  background-image: url(../../assets/11.png);
  width: 224px !important;
  background-size: cover;
}

.logo {
  float: right;
  width: 176px !important;
  margin: 20px 9px 25px 18px;
}

.text_container {
  position: relative;

}

.heading {
  position: absolute;
  color: #fff;
  right: 264px;
  font-size: 58px;
  margin: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}