.container {
    margin-top: -43px;
    height: calc(100% + 43px);
    background: #fff;

    /* // height: 100%; */
 
}
.card1 {
    padding-bottom: 0;
    height: 100% !important;
    border: 0 !important;
}

.card_content {
    position: relative;
    height: 100% !important;
    overflow: hidden;
    margin: 0 -10px;
    background-image: url(../../assets/yello.PNG);
}

.messages_list {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    list-style: none;
    margin: 0;
}

.li {
    background: #004c9c;
    padding: 8px 16px;
    border-radius: 30px;
    margin-right: 12px;
    text-align: right;
    position: relative;
    width: calc(100% - 24px) !important;
    /* //   margin: 20px 50px 35px 20px;
    //  margin-top: 35px !important;
    //  margin-bottom: 5px;
    //  margin-bottom: 5px;
    //  margin-top: 5px; */
}

.name {
    /* // position: absolute;
    // right: -30px;
    //  bottom: -30px;
    //  font-weight: 600; */
    color: rgb(255, 255, 255, 0.6);
    font-size: 18px;
}

.message {
    color: #fff;
    font-size: 20px;

    
}
.message_date {
    font-size: 12px;
    color: rgb(255, 255, 255, 0.4);
    text-align: left;
}

.floors-list {
    margin-top: 200px;
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper_slides {

    height: auto !important;
    justify-content: center;
    align-items: center;
}