.media_slides {
  height: 100%;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
}

.media_swiper {
  background-color: white;
  width: 100%;
  height: 100%;
}

.landscape_img {
  object-fit: cover;
  width: 100% !important;
}

.portrait_img {
  object-fit: cover;
  height: 100% !important;
}

.img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}