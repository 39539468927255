@font-face {
  font-family: "Almoni";
  font-style: normal;
  font-weight: 400;
  src: local("Almoni"), local("Almoni-Light"),
    url(assets/fonts/almoni/ALMONITZARDL4.0AAA-LIGHT.OTF) format("opentype");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* Almoni latin */
@font-face {
  font-family: "Almoni";
  font-style: normal;
  font-weight: 400;
  src: local("Almoni"), local("Almoni-Light"),
    url(assets/fonts/almoni/ALMONITZARDL4.0AAA-LIGHT.OTF) format("opentype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.container-height {
  height: calc(100% - 252px) !important;
}

html,
body {
  zoom: 90%;
  height: 100%;
  background: #e6edf5;
  direction: rtl;
  font-family: "Heebo", sans-serif;
  overflow: hidden;
}


.card {
  background: #fff;
  padding: 8px;
  margin-bottom: 8px;
  border: 0;
  border-radius: 0;
}

.card-heading {
  /* background: #66e0ff; */
  margin: -8px -16px 0px -8px;
  position: relative;
  padding: 8px;
  text-align: right;
  padding-right: 20px;
}

.card-header-dot {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  background: #004c9c;
  right: 0;
  bottom: -8px;
  border-bottom-right-radius: 30px;
}

.card-heading-h2 {
  font-weight: 600;
  margin: 0;
}

.card-heading-img {
  float: right;
  width: 40px;
  position: relative;
  top: 3px;
  margin-left: 18px;
}

.messages-list li:before {
  content: "";
  background-image: url(assets/msg-block.PNG);
  width: 30px;
  height: 20px;
  position: absolute;
  right: -9px;
  bottom: 0px;
  background-repeat: no-repeat;
}

.slick-slide:focus {
  outline: 0 !important;
}

.card.bulletin .slick-slide img {
  width: 80px !important;
  height: 64px !important;
}

.loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

svg path,
svg rect {
  fill: #ff6700;
}

.sidebar-image-hidden {
  max-height: calc(100vh - 245px);
  flex: 0 0 calc(100vh - 245px);
}



.news-lines .slick-slide {
  float: right !important;
}

.col-15 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-25 {
  flex: 0 0 20.8333333333%;
  max-width: 20.8333333333%;
}

.col-35 {
  flex: 0 0 29.1666666667%;
  max-width: 29.1666666667%;
}

.col-45 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.col-55 {
  flex: 0 0 45.8333333333%;
  max-width: 45.8333333333%;
}

.col-65 {
  flex: 0 0 54.1666666667%;
  max-width: 54.1666666667%;
}

.col-75 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.col-85 {
  flex: 0 0 70.8333333333%;
  max-width: 70.8333333333%;
}

.col-95 {
  flex: 0 0 79.1666666667%;
  max-width: 79.1666666667%;
}

.col-105 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
}



/* Color scheme */
.color-scheme-blue {
  background-color: #00a4e0;
}

.color-scheme-blue-gradient {
  background-image: linear-gradient(#00ccff 0%, #004c9c 75%);
}

.color-scheme-blue-notic-board-heading {
  background-color: #66e0ff;
}
.color-scheme-blue-notic-board-heading-mic-icon {
  filter: brightness(0);
  background: none;
}

.color-scheme-blue-headline {
  background: #001A33;
}

.color-scheme-blue-headline-time {
  color: #D7263D
}



/* ORANGE */
.color-scheme-orange {
  background-color: #f47318;
  color: white;
}

.color-scheme-orange-gradient {
  background-image: linear-gradient(#f7931e, #f47216 50%);
}

.color-scheme-orange-notic-board-heading {
  background-color: #f47216;
}

.color-scheme-orange-notic-board-heading-mic-icon {
  filter: brightness(100);
  background: none;
}

.color-scheme-orange-headline {
  background: #f47318;
}

.color-scheme-orange-headline-time {
  color: white;
}