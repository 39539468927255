.container {
  /* height: calc(100vh - 252px) !important; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.market {
  flex: 0 0 40px;
  max-height: 40px;
}

.bulletin {
  /* flex: 0 0 calc(100vh - 580px); */
  /* max-height: calc(100vh - 580px) !important; */
  height: 100%;
  overflow: hidden;
  background-color: white;
  padding: 10px;
}

.card.card-3 {
  flex: 0 0 160px;
  max-height: 160px;
  border-radius: 0;
  margin-bottom: 0;
  border: 0;
}

.swiper_slides {
  height: auto !important;

}

.swiper {
  width: 100%;
  height: 100%;
}

.block_MarketRss_hidden {
  /* flex: 0 0 calc(100% - 422px) !important;
  max-height: calc(100% - 422px) !important; */
  flex: 0 0 calc(100% - 168px) !important;
  max-height: calc(100% - 168px) !important;
}

.block_Heading_hidden {
  flex: 0 0 calc(100% - 294px) !important;
  max-height: calc(100% - 294px) !important;
}

.block_MarketRss_hidden_Heading_hidden {
  /* flex: 0 0 calc(100vh - 255px) !important; */
  /* max-height: calc(100% - 255px) !important; */
}

.news_container {}

.text_container {
  padding-right: 5px;
}

.headline {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 600;
  height: 50px;
  overflow: hidden;
}

.headline_subtitle {
  font-size: 12px;
}

.news_pic_container {
  width: 110px;
  float: right;
  margin-left: 10px;

  img {
    width: 100%;
  }
}


/* Candel */

.candel_container {
  height: 100%;
}

.candel_heading {
  background: #66e0ff;
}

.cendel_heading_h3 {
  font-size: 37px;
  margin: 0;
}



.right_side {
  background: #fff9e2;
  position: relative;
  right: 10px;
  text-align: center;
  margin-bottom: -5px;
  height: 112px;
  flex: 0 0 120px;
  max-width: 120px;
  margin-bottom: 0 !important;
}

.shabbat_img {
  width: 82%;
  margin-top: -35px;
  padding: 0 10px;
  width: 87% !important;
  margin-bottom: -45px;
}

.shabbat_ul {
  list-style: none;
  padding: 18px 12px 18px 0;
  text-align: right;
  margin-bottom: 0;
  white-space: nowrap;


}

.shabbat_li {
  color: #004c9c;
  font-weight: 400;
  font-size: 30px;
}

.cendel_time {
  margin-right: 10px;
}


/* .card-right-side {
  flex: 0 0 120px;
  max-width: 120px;
  margin-bottom: 0 !important;
} */

.left_side {
  flex: 0 0 calc(100% - 120px);
  max-width: calc(100% - 120px);
}



.card-content.row.no-gutters {
  height: 100%;
}



@media (max-width: 1600px) {
  .card-heading h3 {
    font-size: 27px;
  }

  .card-content .card-left-side ul li {
    font-size: 24px;

    span {
      margin-left: 20px;
    }
  }
}