.container {
    color: #004c9c;
    text-align: right;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 600;
    height: 100%;
}

.inliine_content {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #004c9c;
}
.inliine_content > * {
    margin-bottom: 0 !important;
 }

.swiper {
    height: 100%;
}

.swiper_slide {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}