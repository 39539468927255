:host {
  display: block;
}

.container {
  position: relative;
}

.logo_container {
  position: absolute;
  right: 0;
  top: -20px;
  width: 80px;
  height: 80px;
}
.logo {
  width: 100%;
}

.slider_container {
  width: calc(100% - 80px);
  float: left;
  height: 60px;
  padding: 15px;
  color: #fff;
  overflow: hidden;
}
.ul {
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.text {
  display: inline-block;
  color: #fff;
  font-size: 23px;
}

.time {
  /* color: #D7263D; */
  background-color: inherit;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 23px;
}